import { DialogService }                               from 'aurelia-dialog';
import { bindable, inject }                            from 'aurelia-framework';
import { PLATFORM }                                    from 'aurelia-pal';
import { BaseListViewModel }                           from 'base-list-view-model';
import { LotInterventionsLotConstructionsRepository }  from 'modules/management/concessions/concessions-tree/lot-interventions/lot-intervention-lot-construction/services/repository';
import { CreateLotInterventionLotConstructionOAModal } from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-oa/create';
import { EditLotInterventionLotConstructionOAModal }   from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-oa/edit';
import { LotConstructionType }                         from 'modules/management/models/lot-construction-type';
import { AppContainer }                                from 'resources/services/app-container';

@inject(AppContainer, LotInterventionsLotConstructionsRepository, DialogService)
export class ListLotInterventionsLotConstructionsOA extends BaseListViewModel {

    listingId = 'management-oa-lot-constructions-lot-interventions-oa-lot-constructions-listing';

    @bindable headerTitle    = 'listing.management.lot-interventions.oa-lot-constructions';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     */
    constructor(appContainer, repository, dialogService) {
        super(appContainer);

        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/simple-list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.concessions.concessions.lot-interventions.oa-lot-constructions.manage',
            'management.concessions.concessions.lot-interventions.oa-lot-constructions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.lotIntervention = params.model;
        this.readonly        = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids, LotConstructionType.OA),
            },
            show:            false,
            edit:            {
                action:  (row) => this.openModal({ ...row, lot_intervention_id: this.lotIntervention.id }, EditLotInterventionLotConstructionOAModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['management.concessions.concessions.lot-interventions.oa-lot-constructions.manage', 'management.concessions.concessions.lot-interventions.oa-lot-constructions.edit']),
            },
            destroy:         !this.readonly && this.appContainer.authenticatedUser.can(['management.concessions.concessions.lot-interventions.oa-lot-constructions.manage', 'management.concessions.concessions.lot-interventions.oa-lot-constructions.delete']),
            destroySelected: !this.readonly,
            buttons:         [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly,
                    action:           () => this.openModal(this, CreateLotInterventionLotConstructionOAModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      !this.readonly,
            sorting:         {
                column:    1,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'entity',
                    name:  'entity_translations.name',
                    title: 'form.field.entity',
                },
                {
                    data:  'team',
                    name:  'team_translations.name',
                    title: 'form.field.team',
                },
                {
                    data:  'user',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.user',
                    type:  'userEntityTeamLot',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search({ ...criteria, lot_intervention_id: this.lotIntervention.id, lot_construction_type_id: LotConstructionType.OA });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
