import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LotInterventionsLotConstructionsRepository extends BaseRepository {
    baseUrl = 'management/concessions/concessions/lot-interventions/lot-constructions';

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @param lotConstructionTypeId
     * @returns {*}
     */
    destroySelected(ids, lotConstructionTypeId) {
        return this.httpClient.delete(`${this.baseUrl}/destroy-selected`, { ids: ids, lot_construction_type_id: lotConstructionTypeId });
    }
}
