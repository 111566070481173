import { inject }                       from 'aurelia-framework';
import { UserEntityTeamLotsRepository } from 'modules/administration/users/user-entity-team-lot/services/repository';
import { LotInterventionsRepository }   from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { LotConstructionType }          from 'modules/management/models/lot-construction-type';
import { LotInterventionLotConstruction } from 'modules/management/models/lot-intervention-lot-construction';

@inject(LotInterventionsRepository, UserEntityTeamLotsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param lotInterventionsRepository
     * @param userEntityTeamLotsRepository
     */
    constructor(lotInterventionsRepository, userEntityTeamLotsRepository) {
        this.lotInterventionsRepository   = lotInterventionsRepository;
        this.userEntityTeamLotsRepository = userEntityTeamLotsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotInterventionLotConstruction}
     */
    model() {
        let model = new LotInterventionLotConstruction();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.collaborator = {
            type:       'text',
            key:        'collaborator',
            label:      'form.field.collaborator',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
            hidden:     viewModel.create,
        };

        this.lot_construction_ids = {
            type:         'duallistbox',
            key:          'lot_construction_ids',
            label:        'form.field.oh',
            size:         12,
            remoteSource: () => this.lotInterventionsRepository.allConstructions(viewModel.lotIntervention, LotConstructionType.OH),
        };

        this.user_entity_team_lot_ids = {
            type:         'duallistbox',
            key:          'user_entity_team_lot_ids',
            label:        'form.field.collaborators',
            size:         12,
            remoteSource: () => this.userEntityTeamLotsRepository.activeByLotIntervention(viewModel.lotIntervention),
            hidden:       !viewModel.create,
        };

        return [
            [this.collaborator],
            [this.lot_construction_ids],
            [this.user_entity_team_lot_ids],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
