import { inject }                     from 'aurelia-framework';
import { ManageStudiesRepository }    from 'modules/management/concrete/manage-studies/services/repository';
import { LotInterventionManageStudy } from 'modules/management/models/lot-intervention-manage-study';

@inject(ManageStudiesRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param manageStudiesRepository
     */
    constructor(manageStudiesRepository) {
        this.manageStudiesRepository = manageStudiesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotInterventionManageStudy}
     */
    model() {
        let model = new LotInterventionManageStudy();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.manage_study_id = {
            type:         'select2',
            key:          'manage_study_id',
            label:        'form.field.specification-book',
            size:         4,
            remoteSource: () => this.manageStudiesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.start_date = {
            type:            'material-ui-date-picker',
            key:             'start_date',
            label:           'form.field.start-date',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        this.finish_date = {
            type:            'material-ui-date-picker',
            key:             'finish_date',
            label:           'form.field.finish-date',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        const schema = [
            [this.manage_study_id, this.start_date, this.finish_date],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
