import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LotInterventionsManageStudiesRepository extends BaseRepository {
    baseUrl            = 'management/concessions/concessions/lot-interventions/manage-studies';
    lotInterventionUrl = 'management/concessions/concessions/lot-interventions';

    /**
     * Performs a search given some criteria
     *
     * @param lotInterventionId
     * @param criteria
     *
     * @returns {*}
     */
    search(lotInterventionId, criteria) {
        return this.httpClient.post(`${this.lotInterventionUrl}/${lotInterventionId}/manage-studies/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param lotInterventionId
     * @param data
     *
     * @returns {*}
     */
    create(lotInterventionId, data) {
        return this.httpClient.post(`${this.lotInterventionUrl}/${lotInterventionId}/manage-studies`, { ...data, lot_intervention_id: lotInterventionId });
    }
}
