import { EventAggregator }         from 'aurelia-event-aggregator';
import { inject }                  from 'aurelia-framework';
import { I18N }                    from 'aurelia-i18n';
import { ManageStudiesRepository } from 'modules/management/concrete/manage-studies/services/repository';
import { BaseFilterFormSchema }    from 'resources/classes/base-filter-form-schema';
import { SessionStorage }          from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ManageStudiesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:         [],
        manage_studies_ids: [],
        start_date:         null,
        finish_date:        null,
        created_at_from:    null,
        created_at_to:      null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param manageStudiesRepository
     */
    constructor(storage, eventAggregator, i18n, manageStudiesRepository) {
        super(storage, eventAggregator, i18n);

        this.manageStudiesRepository = manageStudiesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.manage_studies_ids = {
            type:         'multiselect-native',
            key:          'manage_studies_ids',
            label:        'form.field.specification',
            size:         4,
            required:     false,
            remoteSource: () => this.manageStudiesRepository.all(),
        };

        this.start_date = {
            type:     'material-ui-date-picker',
            key:      'manage_studies_start_date',
            label:    'form.field.start-date',
            size:     4,
            required: false,
        };

        this.finish_date = {
            type:     'material-ui-date-picker',
            key:      'manage_studies_finish_date',
            label:    'form.field.finish-date',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.manage_studies_ids, this.start_date, this.finish_date],
            [this.buttons],
        ];
    }
}
